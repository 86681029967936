import { renderTpl, formatCurrency, uniqueListener, compileTemplates, pickerToRemote, pickerToRemoteLong, pickerToAPI, localePrefix } from './helpers.js';
import { API } from './api.js';
import Cookies from 'js-cookie';
import { RichEditor } from './rich-editor.js';
import { Notifier } from './notifier.js';
import { MediaGallery } from './media-gallery.js';

const stripeStyle = {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
};

const beforeUnloadHandler = function (e) {
  e.preventDefault();
  e.returnValue = '';
};

export class prCreator {
  static instances = [];

  static init() {
    const wrapper = document.querySelector('.pr-creator');
    if (wrapper && !this.instances.some(instance => instance.wrapper === wrapper)) {
      const newInstance = new prCreator(wrapper);
      this.instances.push(newInstance);
      return newInstance;
    } else {
      return undefined;
    }
  }

  constructor(wrapper) {
    this.dom = {
      wrapper
    };
    this.leavingPrevented = false;

    this.initFields();
    this.initDOM();
    this.initNotifier();
    this.initMediaGallery();
    this.initTemplates();
    this.initEditors();
    this.initUploaders();
    this.initOrder();
    this.initTabs();

    this.stripe = Stripe(window._stripe_public_key);
    this.stripeElements = this.stripe.elements();
  };

  gTagEvent(category, action, label, value) {
    const tag = { category, action, label };
    if (typeof value === 'number') tag.value = value;
    try {
      gtag('event', 'frontend', tag);
    } catch (e) {
      console.warn('Cannot set gtag event:', tag);
    }
  };

  preventLeaving(lock = true) {
    if (lock && !this.leavingPrevented) {
      this.leavingPrevented = true;
      this.dom.saveDraft.classList.add('active');
      window.addEventListener('beforeunload', beforeUnloadHandler);
    } else if (!lock && this.leavingPrevented) {
      this.leavingPrevented = false;
      this.dom.saveDraft.classList.remove('active');
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    }
  };

  initFields() {
    this.fields = {
      title: { checkoutSection: 'content', tab: 'content', errors: [] },
      introduction: { checkoutSection: 'content', tab: 'content', errors: [] },
      body: { checkoutSection: 'content', tab: 'content', errors: [] },
      contact_info: { checkoutSection: 'publish_at', tab: 'media-kit', errors: [] },
      save_contact_info_in_user: { checkoutSection: 'media-kit', tab: 'media-kit', errors: [] },
      contact_info_not_public: { checkoutSection: 'media-kit', tab: 'media-kit', errors: [] },
      publish_at: { checkoutSection: 'publish_at', tab: 'publishing', errors: [] },
      skip_approval: { checkoutSection: 'publish_at', tab: 'publishing', errors: [] },
      categories: { checkoutSection: 'categories', tab: 'categories', errors: [] },
      options: { checkoutSection: 'options', tab: 'options', errors: [] },
      plans: { checkoutSection: 'plans', tab: 'options', errors: [] },
      manual_payment: { tab: 'checkout', errors: [] },
      invoice_data_company_name: { tab: 'checkout', errors: [] },
      invoice_data_department: { tab: 'checkout', errors: [] },
      invoice_data_full_name: { tab: 'checkout', errors: [] },
      invoice_data_location: { tab: 'checkout', errors: [] },
      invoice_data_phone: { tab: 'checkout', errors: [] },
      invoice_data_email: { tab: 'checkout', errors: [] },
      save_invoice_data_in_user: { tab: 'checkout', errors: [] },
      guidelines_accepted: { tab: 'checkout', errors: [] },
      terms_accepted: { tab: 'checkout', errors: [] },
      changes_payment_accepted: { tab: 'checkout', errors: [] },
    };
  };

  initDOM() {
    this.dom.arrowsPrev = Array.from(this.dom.wrapper.querySelectorAll('.creator-prev-tab'));
    this.dom.arrowsNext = Array.from(this.dom.wrapper.querySelectorAll('.creator-next-tab'));
    this.dom.saveDraft = this.dom.wrapper.querySelector('.creator-save-draft');
    this.dom.finalizeOrder = this.dom.wrapper.querySelector('.creator-finalize-order');

    this.dom.orderId = this.dom.wrapper.querySelector('[name="id"]');

    this.dom.contentSections = {
      editor: this.dom.wrapper.querySelector('section.editor'),
      contentFile: this.dom.wrapper.querySelector('section.content-file'),
      contentDefaultHeading: this.dom.wrapper.querySelector('.content-default-heading'),
      contentUploadHeading: this.dom.wrapper.querySelector('.content-upload-heading'),
      contentUploadedHeading: this.dom.wrapper.querySelector('.content-uploaded-heading'),
    }
    this.dom.contentForm = this.dom.wrapper.querySelector('form.editor-text');


    this.dom.tabs = Array.from(this.dom.wrapper.querySelectorAll('.tab-link .tab')).reduce((result, elem) => ({ ...result, [elem.id.replace('-tab', '')]: elem }), {});

    this.dom.fields = Object.fromEntries(Object.keys(this.fields).map(name => [name, this.dom.wrapper.querySelector(`[name="${name}"]`)]).filter(([name, entry]) => entry));
    this.dom.errorMsgs = Array.from(this.dom.wrapper.querySelectorAll('.error-msg[for]')).reduce((result, elem) => ({ ...result, [elem.getAttribute('for')]: elem }), {});

    // this.dom.uploaderMediaKit = this.dom.wrapper.querySelector('.uploader-media-kit');
    this.dom.mediaKitList = this.dom.wrapper.querySelector('.media-kit-list');
    this.dom.mediaKitEditor = this.dom.wrapper.querySelector('.media-kit-editor');

    this.dom.categoryFilterField = this.dom.wrapper.querySelector('#category-filter-field');
    this.dom.categoryRegions = this.dom.wrapper.querySelectorAll('.region-group');
    this.dom.categoryParentSelector = '.action-card-parent-category';
    this.dom.categories = this.dom.wrapper.querySelectorAll('a[data-type="category"]');
    this.dom.options = this.dom.wrapper.querySelectorAll('a[data-type="option"]');
    this.dom.plans = this.dom.wrapper.querySelectorAll('a[data-type="plan"]');

    this.dom.paymentMethods = this.dom.wrapper.querySelectorAll('a[data-type="payment-type"]');
    this.dom.invoiceData = this.dom.wrapper.querySelector('.invoice-data');

    this.dom.modals = {
      stripe: this.dom.wrapper.querySelector('#modal-stripe'),
      bankTransfer: this.dom.wrapper.querySelector('#modal-manual-payment'),
      inquiry: this.dom.wrapper.querySelector('#modal-inquiry'),
      inquiryConfirmation: this.dom.wrapper.querySelector('#modal-inquiry-confirmation'),
    };

    this.dom.stripe = {
      form: document.getElementById('stripe-payment-form'),
      button: document.getElementById('stripe-request-button'),
      buttonWrapper: document.getElementById('stipe-button-payment'),
      card: document.getElementById('stripe-card-element'),
      cardErrors: document.getElementById('stripe-card-errors'),
      success: document.getElementById('stripe-success'),
    };

    this.dom.inquiry = {
      triggers: this.dom.wrapper.querySelectorAll('.send-inquiry-btn'),
      msg: this.dom.modals.inquiry.querySelector('[name="inquiry-msg"]'),
      submit: this.dom.modals.inquiry.querySelector('#submit-inquiry'),
    };

    Array.from(this.dom.inquiry.triggers).forEach(trigger => {
      uniqueListener(trigger, 'click', this.openInquiryModal);
    })
    uniqueListener(this.dom.inquiry.submit, 'click', this.submitInquiry);

    uniqueListener(this.dom.contentForm, 'submit', e => e.preventDefault());

    uniqueListener(this.dom.saveDraft, 'click', this.handleSaveClick);
    uniqueListener(this.dom.finalizeOrder, 'click', this.handleFinalizeClick);
    uniqueListener(this.dom.fields.guidelines_accepted, 'change', this.handleTermsCheckbox);
    uniqueListener(this.dom.fields.terms_accepted, 'change', this.handleTermsCheckbox);
    uniqueListener(this.dom.fields.changes_payment_accepted, 'change', this.handleTermsCheckbox);

    Array.from(this.dom.paymentMethods).forEach(radio => {
      uniqueListener(radio, 'change', this.handlePaymentMethod);
    })

    Object.values(this.dom.fields).forEach(field => uniqueListener(field, 'change', () => {
      this.preventLeaving();
      try {
        this.fields[field.name].errors.forEach(err => {
          const index = this.order[this.fields[field.name].checkoutSection].errors.indexOf(err);
          if (index > -1) {
            this.order[this.fields[field.name].checkoutSection].errors.splice(index, 1);
          }
        });
        this.fields[field.name].errors = [];
      } catch (e) { }
      this.refreshErrors();
    }));

    uniqueListener(this.dom.categoryFilterField, 'search', this.handleCategoryFilter);
    uniqueListener(this.dom.categoryFilterField, 'keyup', this.handleCategoryFilter);
    uniqueListener(this.dom.categoryFilterField, 'change', this.handleCategoryFilter);
    Array.from(this.dom.categories).forEach(card => uniqueListener(card, 'click', this.handleCategoryClick));
    Array.from(this.dom.options).forEach(card => uniqueListener(card, 'click', this.handleOptionClick));
    Array.from(this.dom.plans).forEach(card => uniqueListener(card, 'click', this.handlePlanClick));
    Array.from(this.dom.paymentMethods).forEach(card => uniqueListener(card, 'click', this.handlePaymentTypeClick));

    Array.from(document.querySelectorAll('[data-action="save-and-show-modal"]')).forEach(link => {
      uniqueListener(link, 'click', this.handleSignupClick);
    });
  };

  initNotifier() {
    this.notifier = new Notifier();

    // this.notifier.show('upload-progress', {progress: 25});
    // this.notifier.show('upload-success');
    // this.notifier.show('upload-error');
  };

  initEditors() {
    this.editors = {};
    window.dbg = this.editors;

    this.editors.intro = new RichEditor(this.dom.fields.introduction, {
      bindings: {
        tab: {
          key: 9,
          handler: () => this.editors.body.editor.focus(),
        },
        enter: {
          key: 'Enter',
          handler: () => this.editors.body.editor.focus(),
        },
      }
    });
    this.editors.body = new RichEditor(this.dom.fields.body, {
      sideMenu: {
        action: () => this.mediaGallery.open('editor'), // must return a Promise!
        // action: () => this.uploader.uploadImage(), // must return a Promise!
      },
    });

    if (!this.showHideContentSections()) {
      this.dom.fields.title.focus();
    }

  };

  showHideContentSections(argFile) {
    const contentFile = argFile ?? this.mediaGallery.uploader.getContentFile();

    if (contentFile) {
      renderTpl(this.templates.contentFile, contentFile);

      Array.from(this.templates.contentFile.target.querySelectorAll('.file-remove')).forEach(elem => {
        elem.addEventListener('click', this.mediaGallery.uploader.handleFileDeletion);
      });

      if (!this.dom.fields.title.value) {
        this.dom.fields.title.value = contentFile.name;
      }
    }

    this.dom.contentSections.contentDefaultHeading.classList.toggle('d-none', !!contentFile);
    this.dom.contentSections.contentUploadHeading.classList.toggle('d-none', !!contentFile);
    this.dom.contentSections.contentUploadedHeading.classList.toggle('d-none', !contentFile);
    this.dom.contentSections.editor.classList.toggle('d-none', !!contentFile);
    this.dom.contentSections.contentFile.classList.toggle('d-none', !contentFile);

    return !!contentFile;
  }

  initMediaGallery() {
    this.mediaGallery = new MediaGallery({
      notifier: this.notifier,
      initialImages: pageData.order.images.map(API.parseFile.fromPageData),
      initialAttachments: pageData.order.attachments.map(API.parseFile.fromPageData),
      initialMediaKit: pageData.order.media_kit,
      onUploadSuccess: (savedFiles) => new Promise((resolve, reject) => {
        const contentFile = savedFiles.attachments.find(file => file.isContent) || false;
        this.showHideContentSections(contentFile && { ...contentFile, library: 'attachments' });

        this.saveOrder('upload-with-data')
          .then(resData => {

            resolve({
              images: JSON.parse(resData.release.images).map(API.parseFile.fromUpdateAPI),
              attachments: JSON.parse(resData.release.attachments).map(API.parseFile.fromUpdateAPI),
              media_kit: JSON.parse(resData.release['media_kit']),
            });
          }).catch((...args) => {
            reject(...args);
            this.showHideContentSections();
          });
      }),
      isFileUsed: (url) => this.editors.body.isFileUsed(url),
      onFileDeletion: (url, file) => {
        this.editors.body.removeFileUses(url);

        if (file.content && this.dom.fields.title.value === file.name) {
          this.dom.fields.title.value = '';
        }
      },
    });

    this.dom.mediaKitEditor.addEventListener('click', e => {
      e.preventDefault();
      this.mediaGallery.open('media-kit').catch(e => null);
    });
  };

  initUploaders() {
    // const listTplElem = document.querySelector('.tpl-attachment-list');
    const contentUploader = document.querySelector('.content-uploader');
    if (!contentUploader) return;

    contentUploader.addEventListener('click', e => {
      e.preventDefault();
      // this.hideContentSection();
      this.mediaGallery.uploadContentFile();
    });
  };

  initTemplates() {
    this.templates = {
      contentFile: {
        source: this.dom.wrapper.querySelector('.tpl-content-file-info').innerHTML,
        target: this.dom.contentSections.contentFile,
      },
      summary: {
        source: this.dom.wrapper.querySelector('.summary .tpl-summary-table').innerHTML,
        target: this.dom.wrapper.querySelector('.summary .summary-table'),
      },
      checkout: {
        source: this.dom.wrapper.querySelector('.checkout .tpl-summary-table').innerHTML,
        target: this.dom.wrapper.querySelector('.checkout .summary-table'),
      },
    };
    compileTemplates(this.templates);
  };

  initOrder() {
    this.order = {
      content: { price: 0 },
      categories: { price: 0, active: [] },
      options: { price: 0, active: [] },
      plans: { price: 0, active: [] },
      extraServices: { price: 0, priceVal: 0, active: [] },
      total: { net: 0, tax: 0, gross: 0 },
      publish_at: { date: '' },
      save_contact_info_in_user: { value: false },
      contact_info_not_public: { value: false },
      manual_payment: { value: false },
      translation_enabled: { value: false },
    };

    Object.keys(this.order).forEach(key => this.order[key].errors = []);

    Object.entries(pageData.order.extraServices).forEach(([name, value]) => {
      this.order.extraServices.priceVal += value;
      this.order.extraServices.price = this.order.extraServices.priceVal;
      this.order.extraServices.active.push(name);
    });

    Array.from(this.dom.categories).forEach(elem => {
      if (elem.dataset.active === 'true') {
        elem.classList.add('action-card-selected');
        this.toggleCategory(elem.dataset);
      }
    });

    Array.from(this.dom.options).forEach(elem => {
      if (elem.dataset.active === 'true') {
        elem.classList.add('action-card-selected');
        this.toggleOption(elem.dataset);
      }
    });

    Array.from(this.dom.plans).forEach(elem => {
      if (elem.dataset.active === 'true') {
        elem.classList.add('action-card-selected');
        this.togglePlan(elem.dataset);
      }
    });

    this.setPaymentMethod();
    this.refreshSummary();
    this.renderCheckout();
  };

  arrowsEnableDisable(activeTab) {
    const disabled = {
      prev: false,
      next: false,
    };

    if (activeTab.parentElement.matches(':first-of-type')) {
      disabled.prev = true;
    } else if (activeTab.parentElement.matches(':last-of-type')) {
      disabled.next = true;
    }

    this.dom.arrowsPrev.forEach((elem) => {
      elem.disabled = disabled.prev;
    });
    this.dom.arrowsNext.forEach((elem) => {
      elem.disabled = disabled.next;
    });
  }

  initTabs() {
    const initiallyActive = Object.values(this.dom.tabs).find(elem => elem.classList.contains('active'));
    this.arrowsEnableDisable(initiallyActive);
    this.gTagEvent('navigation', 'click - tab', initiallyActive.getAttribute('href').replace(/^#(.*?)(-panel)?$/, '$1'));

    $(Object.values(this.dom.tabs)).on('shown.bs.tab', e => {
      const active = e.currentTarget;
      const activeTab = active.getAttribute('href').replace(/^#(.*?)(-panel)?$/, '$1');
      this.gTagEvent('navigation', 'click - tab', activeTab);

      this.arrowsEnableDisable(active);
      document.body.classList.toggle('summary-hidden', activeTab === 'checkout')

      if (activeTab === 'checkout') {
        this.refreshSummary();
        this.renderCheckout();
      }
    });

    $(Object.values(this.dom.tabs)).on('click', e => {
      e.preventDefault();
      const target = e.currentTarget;
      const targetTab = target.getAttribute('href').replace(/^#(.*?)(-panel)?$/, '$1');

      const activeEntry = Object.entries(this.dom.tabs).find(([key, item]) => item.classList.contains('active'));
      // const active = activeEntry[1];
      const activeTab = activeEntry && activeEntry[0];

      const tabs = Object.keys(this.dom.tabs);
      const activeTabIndex = tabs.indexOf(activeTab)
      const targetTabIndex = tabs.indexOf(targetTab)

      if (targetTabIndex < activeTabIndex) {
        $(target).tab('show');
      } else if (targetTabIndex === activeTabIndex + 1 && activeTab) {
        this.validateStep(activeTab).then(() => {
          $(target).tab('show');
        }).catch((err) => {
          window.debug && console.warn('validation failed for tab', activeTab, ':', err);
        });
      }
    });

    this.arrowsDisabled = false;

    this.dom.arrowsNext.forEach((elem) => {
      elem.addEventListener('click', e => {
        e.preventDefault();
        const tabsArray = Object.values(this.dom.tabs);
        const currentlyActive = tabsArray.findIndex(elem => elem.classList.contains('active'));
        if (!this.arrowsDisabled && currentlyActive < tabsArray.length - 1) {
          this.arrowsDisabled = true; setTimeout(() => { this.arrowsDisabled = false }, 250);
          $(tabsArray[currentlyActive + 1]).trigger('click');
        }
      });
    });

    this.dom.arrowsPrev.forEach((elem) => {
      elem.addEventListener('click', e => {
        e.preventDefault();
        const tabsArray = Object.values(this.dom.tabs);
        const currentlyActive = tabsArray.findIndex(elem => elem.classList.contains('active'));
        if (!this.arrowsDisabled && currentlyActive > 0) {
          this.arrowsDisabled = true; setTimeout(() => { this.arrowsDisabled = false }, 250);
          $(tabsArray[currentlyActive - 1]).trigger('click');
        }
      });
    });
  };

  validateStep(activeStep) {
    if (this.validationInProgress) return Promise.reject('validation in progress');
    this.validationInProgress = true;
    return new Promise((resolve, reject) => {
      const fields = Object.fromEntries(Object.entries(this.fields).filter(([key, item]) => item.tab === activeStep));

      this.refreshOrder();
      this.saveOrder('validate').then(() => {
        API.call({
          endpoint: API.endpoints.release.finalize,
          params: { id: this.dom.orderId.value },
          suffix: 'validate_only=true',
          data: { release: this.order.data },
        }).then(res => {
          resolve();
          this.validationInProgress = false;
        }).catch(e => {
          if (e && e.response && e.response.status === 422 && e.response.data && e.response.data.errors) {
            const errorFields = this.setErrors(e.response.data.errors, fields);
            if (errorFields.length) {
              this.notifier.show('validation-errors');
              reject();
              this.validationInProgress = false;
              return;
            }
          }
          resolve();
          this.validationInProgress = false;
        });
      }).catch(e => {
        window.debug && console.warn('API connection error (validate step):', e);
        this.validationInProgress = false;
      });
    });
  }

  handleCategoryFilter = (e) => {
    const field = e.currentTarget || e.target;
    if (!field) return;

    const value = field.value;
    if (value === this.categoryFilterValue) return;
    this.categoryFilterValue = value;
    this.categoryFilterPatterns = value.split(' ').filter(Boolean).map(str => new RegExp(str, 'i'));

    Array.from(this.dom.categories).forEach(card => {
      const matched = !this.categoryFilterPatterns.length || card.classList.contains('action-card-selected') || (card.dataset?.name && this.categoryFilterPatterns.some(pattern => pattern.test(card.dataset.name)));
      card.parentElement.classList.toggle('d-none', !matched);
    });

    Array.from(this.dom.categoryRegions).forEach(region => {
      const empty = region.querySelectorAll(`${this.dom.categoryParentSelector}:not(.d-none)`).length === 0;
      region.classList.toggle('d-none', empty);
    });
  };

  handleCategoryClick = (e) => {
    e.preventDefault();
    const elem = e.currentTarget;
    const active = elem.dataset.active === 'true' ? false : true
    elem.dataset.active = active;
    elem.classList.toggle('action-card-selected', active);
    this.toggleCategory(elem.dataset);
    this.preventLeaving();

    this.fields['categories'].errors.forEach(err => {
      const index = this.order[this.fields['categories'].checkoutSection].errors.indexOf(err);
      if (index > -1) {
        this.order[this.fields['categories'].checkoutSection].errors.splice(index, 1);
      }
    });
    this.fields['categories'].errors = [];
    this.refreshErrors();
  };

  toggleCategory({ id, name }) {
    const index = this.order.categories.active.findIndex(cat => cat.id === id);
    if (index > -1) {
      this.order.categories.active.splice(index, 1);
    } else {
      this.order.categories.active.push({ id, name: name });
    }
    this.refreshSummary();
  };

  openInquiryModal = (e) => {
    e.preventDefault();

    if (!pageData.user.logged_in && !this.userSignedUp) {
      if (typeof $ !== 'undefined' && $('#authModal-saved').length) {
        $('#authModal').modal('show');
        !window.authCallback && (window.authCallback = {});
        window.authCallback.signup = () => {
          this.userSignedUp = true;
          this.updateReleaseIds();
          $(this.dom.modals.inquiry).modal('show');
        };
      }
    } else {
      $(this.dom.modals.inquiry).modal('show');
    }
  };

  submitInquiry = (e) => {
    e.preventDefault();

    try {
      const message = this.dom.inquiry.msg.value;
      if (message) {
        API.call({
          endpoint: API.endpoints.release.notifications,
          params: { releaseUrl: window.location.href.replace(/(\/releases\/[^/?]+)([/?].*)*$/, '$1') },
          data: {
            release_notification: {
              message,
              notification_type: 'custom_message'
            }
          },
        }).then(res => {
          $(this.dom.modals.inquiry).modal('hide');

          Array.from(this.dom.modals.inquiryConfirmation.querySelectorAll('[data-href-pattern]')).forEach(link => {
            link.href = link.dataset.hrefPattern.replace(/\{id\}/g, this.dom.orderId.value);
          })
          $(this.dom.modals.inquiryConfirmation).modal('show');
        });
      }
    } catch (err) {
      console.warn('error sending comment', err);
    }
  };

  handleOptionClick = (e) => {
    e.preventDefault();
    const elem = e.currentTarget;
    const active = elem.dataset.active === 'true' ? false : true
    elem.dataset.active = active;
    elem.classList.toggle('action-card-selected', active);
    this.toggleOption(elem.dataset);
    this.preventLeaving();
  };

  toggleOption({ id, name, price }) {
    const index = this.order.options.active.findIndex(elem => elem.name === name);
    if (index > -1) {
      this.order.options.active.splice(index, 1);
    } else {
      const { currentPlan } = this.getCurrentPlanDetails();
      let isIncluded;
      if (currentPlan.option_ids) {
        const optionId = parseInt(id, 10);
        isIncluded = currentPlan.option_ids.includes(optionId);
      }
      this.order.options.active.push({ id: id, name: name, price: isIncluded ? 0 : parseFloat(price) });
    }
    this.refreshSummary();
  };

  updatePageDataOrderWithPlan(plan) {
    pageData.order.basePrice = parseFloat(plan.price);
    pageData.order.currentPlanId = plan.id;
    pageData.order.freeCategories = plan.free_categories;
    pageData.order.firstReleaseBasePrice = plan.first_release_discount_price;
  };

  handlePlanClick = (e) => {
    e.preventDefault();
    const elem = e.currentTarget;

    this.dom.plans.forEach(plan => {
      plan.dataset.active = 'false';
      plan.classList.remove('action-card-selected');
    });

    // make active only the choosen plan
    elem.dataset.active = 'true';
    elem.classList.add('action-card-selected');

    const selectedPlan = pageData.plans.find(plan => plan.id === parseInt(elem.dataset.id, 10));

    if (selectedPlan) {
      this.updatePageDataOrderWithPlan(selectedPlan);
    }

    this.togglePlan(elem.dataset);
    this.preventLeaving();
  };

  togglePlan({ id, name, price, optionIds }) {
    this.order.plans.active = [];
    this.order.plans.active.push({ id: id, name: name, price: parseFloat(price) });

    if (optionIds) {
      const optionIdsArray = optionIds.split(',').map(Number);
      if (optionIdsArray.length > 0) {
        this.dom.options.forEach(option => {
          const optionId = parseInt(option.dataset.id, 10);
          const isIncludedInPlan = optionIdsArray.includes(optionId);
          const index = this.order.options.active.findIndex(opt => opt.id === option.dataset.id);
          if (isIncludedInPlan) {
            option.dataset.active = isIncludedInPlan;
            option.classList.toggle('action-card-selected', isIncludedInPlan);

            const includedOption = {
              id: option.dataset.id,
              name: option.dataset.name,
              price: 0
            };
            if (index > -1) { // included and active option
              this.order.options.active[index] = includedOption
            } else { // included and not active option
              this.order.options.active.push(includedOption);
            }
          }
        });
      }
    } else { // not included and active option
      this.dom.options.forEach(option => {
        const index = this.order.options.active.findIndex(opt => opt.id === option.dataset.id);
        if (index !== -1) {
          this.order.options.active[index] = {
            id: option.dataset.id,
            name: option.dataset.name,
            price: parseFloat(option.dataset.price)
          };
        }
      })
    }
    this.preventLeaving();
    this.refreshSummary();
  };

  handlePaymentTypeClick = (e) => {
    e.preventDefault();
    const elem = e.currentTarget;
    elem.dataset.active = true;
    this.dom.paymentMethods.forEach(item => {
      elem.dataset.active = item === elem;
      item.classList.toggle('action-card-selected', item === elem);
    })
    this.setPaymentMethod(elem.dataset.value);
    this.preventLeaving();
  };

  setPaymentMethod = (chosen) => {
    const isManualPayment = chosen === 'postpaid';

    this.order.manual_payment.value = isManualPayment;
    this.dom.invoiceData.classList.toggle('d-none', !isManualPayment);
  }

  handleTermsCheckbox = (e) => {
    const allTermsAccepted = this.dom.fields.guidelines_accepted.checked && this.dom.fields.terms_accepted.checked && this.dom.fields.changes_payment_accepted.checked;
    this.dom.finalizeOrder.classList.toggle('disabled', !allTermsAccepted);

    this.validateTermsCheckbox(e.currentTarget.name);
  };

  validateTermsCheckbox = (requestedTargets) => {
    let isValid = true;
    const targets = [];

    if (!requestedTargets || requestedTargets === 'all') {
      targets.push('guidelines_accepted', 'terms_accepted', 'changes_payment_accepted');
    } else if (typeof requestedTargets === 'string') {
      targets.push(requestedTargets);
    } else if (Array.isArray(requestedTargets)) {
      targets.push(...requestedTargets);
    } else {
      console.error('Invalid argument in validateTermsCheckbox');
      return;
    }

    for (const fieldName of targets) {
      if (!this.dom.fields[fieldName].checked) {
        this.fields[fieldName].errors.push(true);
        isValid = false;
      } else {
        this.fields[fieldName].errors.splice(0);
      }
    }

    this.refreshErrors();

    return isValid;
  };

  handleSaveClick = (e) => {
    e.preventDefault();
    this.saveOrder('save-draft');
  };

  handleSignupClick = (e) => {
    e.preventDefault();
    this.saveOrder('signup');
  };

  handleFinalizeClick = (e) => {
    e.preventDefault();

    if (this.validateTermsCheckbox()) {
      this.finalizeOrder();
    }
  };

  getCurrentPlanDetails = () => {
    const currentPlan = pageData.plans.find(plan => plan.id === pageData.order.currentPlanId);
    const planBasePrice = currentPlan ? currentPlan.price : 0;
    return { currentPlan, planBasePrice };
  };

  calculateTotalNet() {
    const { planBasePrice } = this.getCurrentPlanDetails();

    const { firstReleaseBasePrice, applicableForFirstReleaseDiscount } = pageData.order;
    const { extraServices, categories, options } = this.order;
    const totalBasePrice = (applicableForFirstReleaseDiscount ? firstReleaseBasePrice : planBasePrice) + extraServices.priceVal + categories.price + options.price;
    return totalBasePrice;
  }

  refreshSummary() {
    const { currentPlan, planBasePrice } = this.getCurrentPlanDetails();
    if (currentPlan) {
      this.updatePageDataOrderWithPlan(currentPlan);
    }

    this.order.content.price = pageData.order.applicableForFirstReleaseDiscount ? pageData.order.firstReleaseBasePrice : planBasePrice;
    this.order.categories.price = Math.max(0, this.order.categories.active.length - pageData.order.freeCategories) * pageData.order.categoryPrice;
    this.order.options.price = this.order.options.active.reduce((sum, elem) => sum + elem.price, 0);
    this.order.total.net = this.calculateTotalNet();
    this.order.total.tax = this.order.total.net * (pageData.order.tax || 0);
    this.order.total.gross = this.order.total.net + this.order.total.tax;
    this.order.total.grossNum = this.order.total.gross;
    this.order.publish_at.date = this.dom.fields.publish_at.value && pickerToRemote($(this.dom.fields.publish_at).datetimepicker('getValue'));
    this.order.publish_at.date_long = this.dom.fields.publish_at.value && pickerToRemoteLong($(this.dom.fields.publish_at).datetimepicker('getValue'));

    this.renderSummary();
  };

  currencyKeys = ['price', 'net', 'tax', 'gross'];

  renderSummary() {
    Object.keys(this.order).forEach(key => {
      this.currencyKeys.forEach(currencyKey => {
        if (typeof this.order[key][currencyKey] === 'number') {
          this.order[key][currencyKey] = formatCurrency(this.order[key][currencyKey]);
        }
      });
    });

    renderTpl(this.templates.summary, this.order);
    const tabTriggers = this.templates.summary.target.querySelectorAll('[data-target-tab]');

    for (let trigger of tabTriggers) {
      trigger.addEventListener('click', e => {
        $(this.dom.tabs[e.currentTarget.getAttribute('data-target-tab')]).tab('show');
      });
    }
  };

  renderCheckout() {
    renderTpl(this.templates.checkout, this.order);

    const tabTriggers = this.templates.checkout.target.querySelectorAll('[data-target-tab]');

    for (let trigger of tabTriggers) {
      trigger.addEventListener('click', e => {
        $(this.dom.tabs[e.currentTarget.getAttribute('data-target-tab')]).tab('show');
      });
    }
  };

  refreshOrder() {
    this.refreshSummary();

    this.order.save_contact_info_in_user.value = this.dom.fields.save_contact_info_in_user.checked;
    this.order.contact_info_not_public.value = this.dom.fields.contact_info_not_public.checked;

    this.order.data = {
      title: this.dom.fields.title.value,
      introduction: this.dom.fields.introduction.value,
      body: this.dom.fields.body.value,
      contact_info: this.dom.fields.contact_info.value,
      publish_at: this.dom.fields.publish_at.value && pickerToAPI($(this.dom.fields.publish_at).datetimepicker('getValue')),
      skip_approval: !this.dom.fields.skip_approval.checked,
      contact_info_not_public: this.order.contact_info_not_public.value,
      plan_id: pageData.order.currentPlanId,
      // total_price: this.order.total.price,
      categories: this.order.categories.active.map(cat => cat.id),
      options: this.order.options.active.map(item => item.id),
      ...(this.mediaGallery && this.mediaGallery.getFilesForShrine()),
      manual_payment: this.order.manual_payment.value,
      translation_enabled: this.order.translation_enabled.value,
    };

    if (this.order.data.manual_payment) {
      Object.assign(this.order.data, {
        invoice_data_company_name: this.dom.fields.invoice_data_company_name.value,
        invoice_data_department: this.dom.fields.invoice_data_department.value,
        invoice_data_full_name: this.dom.fields.invoice_data_full_name.value,
        invoice_data_location: this.dom.fields.invoice_data_location.value,
        invoice_data_phone: this.dom.fields.invoice_data_phone.value,
        invoice_data_email: this.dom.fields.invoice_data_email.value,
        save_invoice_data_in_user: this.dom.fields.save_invoice_data_in_user.checked,
      });
    }
  };

  updateReleaseIds(release) {
    if (release) {
      window.currentRelease = {
        id: release.id,
        public_id: release.public_id,
      };
    }

    const activeIdType = pageData.user.logged_in || this.userSignedUp ? 'id' : 'public_id';
    window.currentRelease.activeIdType = activeIdType;

    if (this.dom.orderId.value !== window.currentRelease[activeIdType]) {
      history.pushState(null, document.title, `${localePrefix()}/releases/${window.currentRelease[activeIdType]}/edit`);
      this.dom.orderId.value = window.currentRelease[activeIdType];
    }
  };

  saveOrder(source) {
    return new Promise((resolve, reject) => {
      this.dom.saveDraft.disabled = true;
      this.dom.saveDraft.classList.remove('error');

      if (this.dom.orderId.value === '') {
        if ((source === 'save-draft') && this.notifier) {
          this.notifier.show('saving-progress', { progress: 25 });
        }
        API.call({
          endpoint: API.endpoints.release.create,
          data: { release: { title: '' } },
        }).then(res => {
          try {
            Cookies.set('releaseId', res.data.release.public_id, { expires: 30 });
            this.updateReleaseIds(res.data.release);
            this.saveOrder(source).then(resolve).catch(reject);
            // todo: display notification
          } catch (e) {
            this.dom.saveDraft.classList.add('error');
            console.warn('API error (try create release):', e);
            // todo: display notification
            reject(e);
          };
          this.dom.saveDraft.disabled = false;
        }).catch(e => {
          this.dom.saveDraft.disabled = false;
          this.dom.saveDraft.classList.add('error');
          source === 'save-draft' && this.notifier.show('saving-error');
          console.warn('API connection error (create release):', e);
          // todo: display notification
          reject(e);
        });
      } else {
        this.refreshOrder();

        if ((source === 'save-draft') && this.notifier) {
          this.notifier.show('saving-progress', { progress: 50 });
        }

        API.call({
          endpoint: API.endpoints.release.update,
          params: { id: this.dom.orderId.value },
          data: {
            release: {
              ...this.order.data,
              ...(source === 'upload' && { save_only_files: true }),
              ...(this.order.save_contact_info_in_user.value && { save_contact_info_in_user: this.order.save_contact_info_in_user.value }),
            },
          },
        }).then(res => {
          this.dom.saveDraft.disabled = false;
          source !== 'upload' && this.preventLeaving(false);
          if ((source === 'save-draft') && this.notifier) {
            this.notifier.show('saving-success');
          }
          this.updateReleaseIds(res.data.release);
          if (
            !pageData.user.logged_in && !this.userSignedUp
            && source !== 'signup' && source !== 'upload' && source !== 'upload-with-data' && source !== 'validate'
            && typeof $ !== 'undefined' && $('#authModal-saved').length
          ) {
            $('#authModal-saved').modal('show');
            !window.authCallback && (window.authCallback = {});
            window.authCallback.signup = () => {
              this.userSignedUp = true;
              this.updateReleaseIds();
              this.finalizeOrder();
            };
          }
          // todo: display notification
          resolve(res.data);
        }).catch(e => {
          this.dom.saveDraft.disabled = false;
          this.dom.saveDraft.classList.add('error');
          source === 'save-draft' && this.notifier.show('saving-error');
          window.debug && console.warn('API connection error (update release):', e);
          // todo: display notification
          reject(e);
          if (e && e.response && e.response.status === 422 && e.response.data && e.response.data.errors) {
            this.setErrors(e.response.data.errors);
          }
        });
      }
    });
  };

  finalizeOrder() {
    this.dom.finalizeOrder.disabled = true;
    this.dom.finalizeOrder.classList.remove('error');

    this.refreshOrder();
    this.saveOrder('finalize').then(() => {
      // todo: validate
      if (!pageData.user.logged_in && !this.userSignedUp) {
        this.dom.finalizeOrder.disabled = false;
      } else {
        API.call({
          endpoint: API.endpoints.release.finalize,
          params: { id: this.dom.orderId.value },
          data: { release: this.order.data },
        }).then(res => {
          this.dom.finalizeOrder.disabled = false;
          if (res.data.payment.payment_method === "manual") {
            this.showTransferDetails();
          } else {
            try {
              this.startPayment(res.data.payment);
            } catch (e) {
              console.warn('payment info error:', e);
            }
          }
        }).catch(e => {
          this.dom.finalizeOrder.disabled = false;
          this.dom.finalizeOrder.classList.add('error');
          // console.warn('API connection error (finalize release):', Object.entries(e));
          if (e && e.response && e.response.status === 422 && e.response.data && e.response.data.errors) {
            this.setErrors(e.response.data.errors);
            this.notifier.show('validation-errors');
          }
        });
      }
    }).catch(e => {
      this.dom.finalizeOrder.disabled = false;
      this.dom.finalizeOrder.classList.add('error');
      window.debug && console.warn('API connection error (update release):', e);
    });
  };

  setErrors(errors, providedFields) {
    const checkedFields = providedFields || this.fields;
    const handleCheckout = !providedFields;

    const output = [];

    handleCheckout && Object.keys(this.order).forEach(key => this.order[key].errors = []);
    Object.keys(checkedFields).forEach(key => checkedFields[key].errors = []);

    Object.entries(errors).forEach(([name, msgs]) => {
      if (typeof checkedFields[name] !== 'undefined') {
        checkedFields[name].errors.push(...msgs);
        output.push(name);
        if (handleCheckout && typeof this.order[checkedFields[name].checkoutSection] !== 'undefined') {
          this.order[checkedFields[name].checkoutSection].errors.push(...msgs);
        }
      }
    });

    this.refreshErrors();
    return output;
  };

  refreshErrors() {
    // const tabsWithErrors = [];

    Object.entries(this.fields).forEach(([name, fieldConfig]) => {
      const elem = this.dom.fields[name];
      elem && elem.classList.toggle('error', fieldConfig.errors.length);
      // fieldConfig.errors.length && tabsWithErrors.push(fieldConfig.tab);
      if (elem && elem.id) {
        Array.from(this.dom.wrapper.querySelectorAll(`[for="${elem.id}"]`)).forEach(label => {
          label.classList.toggle('error', fieldConfig.errors.length);
        })
      }
    });

    // Object.entries(this.dom.tabs).forEach(([name, elem]) => {
    //   elem.classList.toggle('error', tabsWithErrors.includes(name));
    // });

    Object.entries(this.dom.errorMsgs).forEach(([name, elem]) => {
      elem.innerHTML = this.fields[name].errors.length ? this.fields[name].errors.join(' ') : '';
    });

    this.renderCheckout();
  };

  showTransferDetails() {
    const data = Object.assign({}, this.order, {
      id: this.dom.orderId.value,
    });

    this.gTagEvent('user action', 'order completed', 'invoice payment', this.order.total.grossNum);

    Array.from(this.dom.modals.bankTransfer.querySelectorAll('[data-href-pattern]')).forEach(link => {
      link.href = link.dataset.hrefPattern.replace(/\{id\}/g, this.dom.orderId.value);
    })
    $(this.dom.modals.bankTransfer).modal('show');
  }

  startPayment(receivedIntent) {
    this.startWalletPayment(receivedIntent);
    this.startCardPayment(receivedIntent);
    $(this.dom.modals.stripe).modal('show');
  };

  startWalletPayment(receivedIntent) {
    if (typeof this.walletPayment === 'undefined') {
      this.walletPayment = {};
      this.walletPayment.receivedIntent = receivedIntent;

      this.walletPayment.paymentRequest = this.stripe.paymentRequest({
        country: 'JP',
        currency: 'jpy',
        total: {
          label: 'Total',
          amount: this.walletPayment.receivedIntent.amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      this.walletPayment.prButton = this.stripeElements.create('paymentRequestButton', {
        paymentRequest: this.walletPayment.paymentRequest,
        style: {
          paymentRequestButton: {
            // type: 'buy', // One of 'default', 'book', 'buy', or 'donate'
            theme: 'light-outline', // One of 'dark', 'light', or 'light-outline' // Defaults to 'dark'
            height: '50px', // Defaults to '40px'. The width is always '100%'.
          },
        },
      });

      (async () => {
        // Check the availability of the Payment Request API first.
        const result = await this.walletPayment.paymentRequest.canMakePayment();
        if (result) {
          this.walletPayment.prButton.mount(this.dom.stripe.button);
          this.dom.stripe.buttonWrapper.style.display = 'block';
        } else {
          this.dom.stripe.buttonWrapper.style.display = 'none';
        }
      })();

      this.walletPayment.paymentRequest.on('paymentmethod', async (ev) => {
        // Confirm the PaymentIntent without handling potential next actions (yet).
        const { paymentIntent, error: confirmError } = await this.stripe.confirmCardPayment(
          this.walletPayment.receivedIntent.client_secret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete('fail');
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete('success');
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11" instead
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await this.stripe.confirmCardPayment(clientSecret);
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
              this.handlePaymentError();
            } else {
              // The payment has succeeded.
              this.handlePaymentSuccess();
            }
          } else {
            // The payment has succeeded.
            this.handlePaymentSuccess();
          }
        }
      });

    } else {
      this.walletPayment.receivedIntent = receivedIntent;

      this.walletPayment.paymentRequest.update({
        total: {
          label: 'Total',
          amount: this.walletPayment.receivedIntent.amount,
        },
      });

      /* dev note: probably not necessary, it's still the same object */
      // this.walletPayment.prButton.update({
      //   paymentRequest: this.walletPayment.paymentRequest,
      // });
    }
  };

  startCardPayment(receivedIntent) {
    if (typeof this.cardPayment === 'undefined') {

      this.cardPayment = {};
      this.cardPayment.receivedIntent = receivedIntent;

      this.cardPayment.card = this.stripeElements.create("card", { style: stripeStyle });
      this.cardPayment.card.mount(this.dom.stripe.card);

      this.cardPayment.card.on('change', (event) => {
        this.dom.stripe.cardErrors.textContent = event.error ? event.error.message : '';
      });

      this.dom.stripe.form.addEventListener('submit', (ev) => {
        ev.preventDefault();
        this.stripe.confirmCardPayment(this.cardPayment.receivedIntent.client_secret, {
          payment_method: {
            card: this.cardPayment.card,
            // billing_details: {
            //   name: 'John Doe'
            // }
          }
        }).then(result => {
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            this.handlePaymentError(result.error.message);
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer
              // There's a risk of the customer closing the window before callback
              // execution. Set up a webhook or plugin to listen for the
              // payment_intent.succeeded event that handles any business critical
              // post-payment actions.

              this.handlePaymentSuccess();
            } else {
              // this case isn't well documented, but it seems this is an error
              console.warn('Error during payment process:', result);
              this.handlePaymentError();
            }
          }
        });
      });

    } else {

      this.cardPayment.receivedIntent = receivedIntent;

    }
  };

  handlePaymentSuccess() {
    this.gTagEvent('user action', 'order completed', 'paid via PayPal', this.order.total.grossNum);
    this.dom.stripe.form.classList.add('d-none');

    Array.from(this.dom.stripe.success.querySelectorAll('[data-href-pattern]')).forEach(link => {
      link.href = link.dataset.hrefPattern.replace(/\{id\}/g, this.dom.orderId.value);
    })

    this.dom.stripe.success.classList.remove('d-none');

    API.call({
      endpoint: API.endpoints.release.lock,
      params: { id: this.dom.orderId.value },
    }).catch(e => {
      console.warn('API connection error (lock release):', Object.entries(e));
    });
  };

  handlePaymentError(msg) {
    this.dom.stripe.cardErrors.textContent = msg || '';
  };
};
